import $ from 'jquery';

export default class {
  constructor() {
    this.preSubmitCallbacks = [];
    this.isInProgress = false;
  }

  addPreSubmitCallback(callback) {
      this.preSubmitCallbacks.push(callback);
  }

  submitWithRemoteValidation(id) {

    const form = document.getElementById(id);
    form.addEventListener('submit', event => {
      event.preventDefault();
      event.stopPropagation();

      if (!this.isInProgress) {
        this.isInProgress = true;

        for (const callback of this.preSubmitCallbacks) {
          callback();
        }

        if (window.CKEDITOR !== undefined) {
          for (const i in CKEDITOR.instances) {
            CKEDITOR.instances[i].updateElement();
          }
        }

        const thisValidator = this;

        $.ajax({
          url: form.action,
          type: 'POST',
          data: new FormData(form),
          processData: false,
          contentType: false,
          dataType: 'json',
          success: function (response) {
            if (response.status === 'created' || response.status === 'ok') {
              location.replace(response.redirect);
            } else {
              console.error(response);
            }
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(textStatus);
          },
          complete: function (jqXHR, textStatus) {
            thisValidator.isInProgress = false;
          }
        });
      }
      return false;
    });
  }
}