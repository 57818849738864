import autoComplete from "@tarekraafat/autocomplete.js";
import { dictToQueryParamString } from 'utils';

export function omsAutoComplete({
  selector,
  serviceUrl,
  formatResult,
  waitForEnter = false,
  treshold = 3,
  params = {},
  onSelect = () => {},
  maxResults = 20
}) {
  const input = (typeof selector === 'string') ? document.querySelector(selector) : selector();
  const className = 'autocomplete-suggestions';
  // crypto is only available in HTTPS, so we need something else for dev and test
  const uniqueness = ['development', 'test'].includes(NODE_ENV) ? Math.random() : crypto.randomUUID();
  const idName = input.id ? `${input.id}-autocomplete-suggestions` : `${uniqueness}-autocomplete-suggestions`;

  input.setAttribute('autocomplete', 'off');

  input.addEventListener('autoComplete', () => {
    const inputWidth = input.offsetWidth;
    const inputPos = input.getBoundingClientRect();
    let list = document.getElementById(idName);
    list.style.top = `${inputPos.bottom + window.pageYOffset}px`;
    list.style.left = `${inputPos.left + window.pageXOffset}px`;
    list.style.width = `${inputWidth}px`;
  });

  if (waitForEnter) {
    const enterEvent = new Event('enterDown');
    input.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        input.dispatchEvent(enterEvent);
      }
    })
  }

  document.body.addEventListener('click', () => {
    document.querySelectorAll(`.${className}`).forEach(item => item.innerHTML = '');
  });

  new autoComplete({
    data: {
      src: async() => {
        params.limit = maxResults;
        params.query = input.value;
        const realServiceUrl = (typeof serviceUrl === 'function') ? serviceUrl() : serviceUrl;
        const paramSeparator = realServiceUrl.includes('?') ? '&' : '?';
        const source = await fetch(`${realServiceUrl}${paramSeparator}${dictToQueryParamString(params)}`);
        const data = await source.json();
        return data.suggestions;
      },
      key: ['value'],
      cache: false
    },
    selector: selector,
    debounce: 300,
    treshold: treshold,
    maxResults: maxResults,
    trigger: waitForEnter ? { event: ["enterDown"], condition: false } : { event: ["input"], condition: false },
    resultsList: {
      render: true,
      container: source => {
        source.setAttribute('class', className);
        source.setAttribute('id', idName);
      },
      destination: document.querySelector('body'),
      position: 'beforeend',
      element: 'div',
      highlight: true
    },
    resultItem: {
      content: (data, source) => {
        let finalMatch = (typeof formatResult === 'function') ? formatResult(data.value) : data.match;
        const inputRegex = new RegExp(input.value, 'gi');
        source.innerHTML = finalMatch.replace(inputRegex, match => { return `<strong>${match}</strong>`; });
        source.title = finalMatch;
        source.setAttribute('class', 'autocomplete-suggestion');

      },
      element: 'div'
    },
    noResults: () => {
      const result = document.createElement('div');
      result.setAttribute('class', 'autocomplete-suggestion');
      result.innerHTML = 'Nincs találat';
      document.querySelector('.autocomplete-suggestions').appendChild(result);
    },
    onSelection: feedback => {
      input.value = feedback.selection.match;
      onSelect(feedback.selection.value);
    }
  });
  input.addEventListener('keypress', event => {
    if (event.key === 'Enter') {
      let list = document.getElementById(idName);
      list.innerHTML = '';
    }
  });
  const observer = new MutationObserver(() => {
    function isDetached(element) {
      if (element.parentNode === document) {
        return false;
      }
      else if (element.parentNode === null) {
        return true;
      }
      else {
        return isDetached(element.parentNode);
      }
    }
    if (isDetached(input)) {
      observer.disconnect();
      document.getElementById(idName)?.remove();
    }
  });
  observer.observe(document, {
    childList: true,
    subtree: true
  });
}

