import $ from 'jquery';

function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  }
  else
  {
    begin += 2;
  }
  var end = document.cookie.indexOf(";", begin);
  if (end == -1) {
    end = dc.length;
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}

function setCookie(name, value, days) {
  var domain = "";
  //didn't want to include a whole lot of code to do it right for all possible domain suffixes...
  if (location.hostname.startsWith('en.')) {
    domain = location.hostname.substr(location.hostname.indexOf('.'));
  }
  else {
    domain = '.' + location.hostname;
  }
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/;domain=" + domain;
}

function initCookieServices(consent) {
  // don't do anything during turbolinks cache preview
  if ($('html').attr('data-turbolinks-preview') != null) {
    return;
  }

  var ga_adm_cookie = getCookie('no_ga_adm');
  var ga_akr_cookie = getCookie('no_ga_akr');

  if (ga_adm_cookie == null && ga_akr_cookie == null && consent[0] === '1') {
    gaInit();
  }
  if (consent[1] === '1') {
    hotjarInit();
  }
  if (consent[2] === '1') {
    recaptchaInit();
  }
  else {
    noreCaptcha();
  }
}

function useBasicCookies() {
  setCookie('CONSENT', '110', 365);
  initCookieServices('110');
}

function useAllCookies() {
  setCookie('CONSENT', '111', 365);
  initCookieServices('111');
}

export function consent(settings_page) {
  //this is not really idempotent,
  //so don't do anything during turbolinks cache preview
  if ($('html').attr('data-turbolinks-preview') != null) {
    return;
  }

  initCookieServices('110');

  var storedConsent = '111';//getCookie('CONSENT');

  if (storedConsent === null) {

    if (window.location.pathname === settings_page) {
      $('#vf-instant-dropdown').show();
      $('#vf-footer-consent').hide();

      $('#vf-basic_cookies').click(function(event) {
        event.preventDefault();

        useBasicCookies();

        $(this).prop('disabled', true);
        $('#vf-full_cookies').prop('disabled', false);
      });
      $('#vf-full_cookies').click(function(event) {
        event.preventDefault();

        useAllCookies();

        $(this).prop('disabled', true);
        $('#vf-basic_cookies').prop('disabled', false);
      });

    }
    else {
      $('#vf-instant-dropdown').hide();
      $('#vf-footer-consent').show();

      $('#vf-footer-basic_cookies').click(function(event) {
        event.preventDefault();

        useBasicCookies();
        $('#vf-instant-dropdown').show();
        $('#vf-footer-consent').hide();
      });
      $('#vf-footer-full_cookies').click(function(event) {
        event.preventDefault();

        useAllCookies();
        $('#vf-instant-dropdown').show();
        $('#vf-footer-consent').hide();
      });
    }

  }
  else {
    $('#vf-instant-dropdown').show();
    $('#vf-footer-consent').hide();

    if (storedConsent !== '111') {
      storedConsent = '110';
      setCookie('CONSENT', storedConsent, 365);
    }

    initCookieServices(storedConsent);

    if (window.location.pathname === settings_page) {

      $('#vf-basic_cookies').click(function(event) {
        event.preventDefault();

        useBasicCookies();

        location.replace(location.pathname)
      });
      $('#vf-full_cookies').click(function(event) {
        event.preventDefault();

        useAllCookies();

        location.replace(location.pathname)
      });
    }
  }
}

function recaptchaInit() {
  var srcUrl = 'https://www.google.com/recaptcha/api.js?onload=onLoadCallback&render=explicit';
  // dont create it again if it exists
  if ($('head script[src="' + srcUrl + '"]').length) {
    // no need to load the recaptcha code again, but we do need to actually render it
    onLoadCallback();
  }
  else {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.src = srcUrl;
    script.type = 'text/javascript';
    script.async = 1;

    head.appendChild(script);
  }
  $('.vf-norecaptcha').css('display', 'none');
  $('#vf-instant-button').prop('disabled', false);
}

function noreCaptcha() {
  $('.vf-norecaptcha').css('display', 'block');
  if ($('#vf-instant-button').length) {
    $('#vf-instant-button').prop('disabled', true);
  }
  if ($('#vf-feedback-button').length) {
    $('#vf-feedback-button').prop('disabled', true);
  }
}

function hotjarInit() {
  if (typeof hj !== 'function') {
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:710990,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
}

function gaInit()
{
  if (typeof ga !== 'function') {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', 'UA-13156846-1', 'auto');
    ga('set', 'anonymizeIp', true);
  }

  ga('set', 'location', window.location.href);
  ga('send', 'pageview');
}
