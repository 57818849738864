/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import '../stylesheets/application';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

import { pascalToSnake } from '../utils';
import { applicationHeader, progress, instant, captchaLoad, dropdown } from '../views';
import { consent } from '../tracking';

Rails.start();
Turbolinks.start();

window.query = document.querySelector.bind(document);
window.queryAll = document.querySelectorAll.bind(document);
window.globalListener = document.addEventListener.bind(document);

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(search, pos) {
    return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
  };
}

applicationHeader();


['DOMContentLoaded', 'turbolinks:load'].forEach(e => {
  document.addEventListener(e, () => {
    const body = document.querySelector('body');
    query('#vf-instant-dropdown a').addEventListener('click', event => {
      event.preventDefault();
      const instantForm = query('#vf-instant-form');
      if (getComputedStyle(instantForm).display !== 'none') {
        instantForm.style.display = 'none';
      } else {
        instantForm.style.display = 'flex';
      }
    });

    dropdown();
    progress();
    consent('#{url_for_i18n(:privacy_policy)}');
    window.vfInstantButtonCaptchaSubmit = instant({ isMobile: false });
    window.onLoadCallback = captchaLoad;
    window.recaptchaSiteKey = body.dataset.recaptcha;


    const controller = pascalToSnake(body.dataset.controller);
    const action = body.dataset.action;
    const variant = body.dataset.variant;
    const namespaces = body.dataset.namespace ? body
      .dataset
      .namespace
      .split('::')
      .map(pascalToSnake) : []

    if (namespaces.length === 0) {
      import(`../views/${controller}`).then(module => {
        const viewInitializer = module[`init${action}`];
        if (typeof viewInitializer === 'function') {
          viewInitializer({isMobile: variant === 'mobile'})
        }
      }).catch(error => console.log(error));
    } else {
      import(`../views/${namespaces.join('/')}/${controller}`).then(module => {
        const viewInitializer = module[`init${action}`];
        if (typeof viewInitializer === 'function') {
          viewInitializer({isMobile: variant === 'mobile'})
        }
      }).catch(error => console.log(error));
    }
  });
});
